/* src/ContactUs.css */

.contact-us {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px; 
    /* border: 1px solid #ccc; */
    border-radius: 5px;
    background-color: transparent;
    margin-top: 10px;
  }
  
  .contact-us h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .contact-us p {
    text-align: center;
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .map-container {
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* Button container for WhatsApp and Gmail buttons */
  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px; /* Space between buttons */
  }
  
  /* Attention button shared styles */
  .attention-button {
    background-color: #25D366; /* WhatsApp green */
    color: white;
    font-size: 20px;
    padding: 15px 30px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.3s, background-color 0.3s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
  }
  
  .attention-button:hover {
    transform: scale(1.05);
    background-color: #128C7E; /* Darker green on hover */
  }
  
  .attention-button i {
    margin-left: 10px;
    font-size: 24px;
  }
  
  /* Gmail specific button styles */
  .attention-button.gmail {
    background-color: #D44638; /* Gmail red */
  }
  
  .attention-button.gmail:hover {
    background-color: #B23121; /* Darker red on hover */
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .form-group textarea {
    height: 100px;
    resize: vertical;
  }
  
  .submit-button {
    background-color: #007bff;
    color: white;
    font-size: 18px;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  

  @media (max-width: 768px) {
    .attention-button {
      font-size: 16px;
      padding: 8px 16px;
    }
  
    .button-container {
      flex-direction: column;
      gap: 10px;
    }
  
    .map-container iframe {
      width: 100%;
      height: 300px;
    }
  }
  