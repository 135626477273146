* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.services,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.products {
  background-image: url('../public//images/img-9.jpg');
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  background-image: url('../public//images/img-8.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

/* wa & gmail buttons */
.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px; /* Space between buttons */
}

.attention-button {
  background-color: #25D366; /* WhatsApp green */
  color: white;
  font-size: 20px;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s, background-color 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
}

.attention-button:hover {
  transform: scale(1.05);
  background-color: #128C7E; /* Darker green on hover */
}

.attention-button i {
  margin-left: 10px;
  font-size: 24px;
}

.attention-button.gmail {
  background-color: #D44638; /* Gmail red */
}

.attention-button.gmail:hover {
  background-color: #B23121; /* Darker red on hover */
}
