.reviews-container {
    display: flex;
    overflow-x: auto;
    padding: 20px;
    gap: 20px;
    scroll-snap-type: x mandatory;
  }
  
  .review-box {
    flex: 0 0 auto;
    background: #f4f4f4;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    max-width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    scroll-snap-align: start;
    white-space: normal;
    word-wrap: break-word;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
  }
  
  .review-box p {
    margin: 0;
    line-height: 1.5;
    max-height: 7.5em; /* 5 lines */
    overflow: hidden;
  }
  
  .review-box.expanded p {
    max-height: none;
  }
  
  .review-box button {
    align-self: flex-end;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .review-box button:hover {
    background-color: #0056b3;
  }

  .review-author {
    margin: 0 0 10px 0;
    font-size: 1.1em;
    font-weight: bold;
  }
  
  @media only screen and (max-width: 1024px) {
    .review-box {
      max-width: 65vw;
    }
  }